<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1000"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="帖子ID"
          prop="postId"
          v-bind="formItemLayout"
      >
        <a-input disabled v-model="form.postId" v-decorator="['postId']" style="width:200px"/>
      </a-form-model-item>
      <a-form-model-item
          v-show="targetType==='comment'"
          label="评论ID"
          prop="commentId"
          v-bind="formItemLayout"
      >
        <a-input disabled v-model="form.commentId" v-decorator="['commentId']" style="width:200px"/>
      </a-form-model-item>
      <a-form-model-item
          label="自动评论内容"
          prop="comments"
          v-bind="formItemLayout"
      >
        <a-textarea v-model="form.comments" v-decorator="['comments']" style="height:200px"
                    placeholder="每行表示一个独立评论，后台随机分配用户"/>
      </a-form-model-item>
      <a-form-model-item
          label="已执行次数"
          prop="doneCount"
          v-bind="formItemLayout"
      >
        <a-input-number disabled v-model="form.doneCount" v-decorator="['doneCount']" :min="0" style="width:200px"/>
      </a-form-model-item>
      <a-form-model-item
          label="开始执行时间"
          prop="beginTime"
          v-bind="formItemLayout"
      >
        <a-date-picker v-model="form.beginTime" show-time v-decorator="['beginTime']" @ok="onBeginTimeOk"
                       :disabledDate="disabledDate"/>
      </a-form-model-item>
      <a-form-model-item
          label="当前状态"
          prop="status"
          v-bind="formItemLayout"
      >
        <a-radio-group v-model="form.status">
          <a-radio :value="1">正常</a-radio>
          <a-radio :value="0">取消</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "PostCommentTaskModal",
  data() {
    return {
      visible: false,
      title: "",
      formItemLayout: {
        labelCol: {span: 4},
        wrapperCol: {span: 19},
      },
      confirmLoading: false,
      postId: 0,
      commentId: 0,
      targetType: 'post',//comment
      record: {},
      type: 2,//类型（1--自动点赞，2--自动评论，3--评论自动点赞，4--评论自动评论）
      form: {},
      rules: {
        comments: [
          {
            required: true,
            message: "请输入自动评论内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    showModal(record, targetType) {
      this.visible = true;
      this.targetType = targetType || 'post'
      this.record = record;//记录可能是帖子或者评论
      if (this.targetType === 'post') {
        this.postId = record.id;
        this.commentId = 0;
        this.type = 2;
        this.title = "自动评论任务帖子：" + this.postId;
      } else {
        this.postId = record.postId;
        this.commentId = record.id;
        this.type = 4;
        this.title = "自动评论任务：帖子ID=" + this.postId + "，评论ID=" + this.commentId;
      }
      this.$get("/postPrizeTask/info", {postId: this.postId, commentId: this.commentId, type: this.type}).then(res => {
        this.form = res.data || {
          postId: this.postId,
          status: 1,
          commentId: this.commentId,
          doneCount: 0,
          type: this.type,
          comments:""
        }
      })
    },
    disabledDate(current) {
      return false;
      // return current && current < moment().endOf('day');
    },
    onBeginTimeOk(value) {
      console.log(value)
      this.form.beginTime = value.format("YYYY-MM-DD HH:mm:ss")
    },
    handleOk(operate) {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          let data = {
            ...this.form,
            type: this.type
          };

          this.$postJson('postPrizeTask/save', data)
              .then((res) => {
                this.confirmLoading = false;
                this.visible = false;
                this.$emit("success", operate);
              })
              .catch((error) => {
                this.confirmLoading = false;
              });
        }
      })
    },
  }
};
</script>

<style lang="less" scoped>
.modal-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.list-div {
  max-height: 200px;
  overflow-y: auto;
  padding-top: 10px;

  /deep/ .ant-timeline-item {
    padding-bottom: 10px;
  }

  /deep/ .ant-timeline-item-content {
    min-height: auto;
  }
}
</style>
